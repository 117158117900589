import * as React from "react"
import aritro from '../../images/aritro.png'

import '../../styles/home.css'
import '../../styles/skeleton.css'
import '../../styles/normalize.css'


import Header from "../../components/Header"
import Item from "../../components/Item"
import Footer from "../../components/Footer"
import Socials from "../../components/Socials"
import Meta from "../../components/Meta"

import resume from "../../static/Resume.pdf"

const IndexPage = () => {
    return (
      <div className="container main">
        <title>About</title>
        <Meta></Meta>
        <Header selected="about"></Header>
        <div className="header row">
          <div className="title-secondary eight columns">
            <div className="intro">
              <h6>HELLO!</h6>
            </div>
            <div className="headline">
              <h1>Nice to meet you!</h1>
            </div>
            <div className="bio">
              <br/>
              <h6>I am a Human Computer Interaction student at the University of Maryland, College Park.</h6>
              <h6>I am a CSE graduate from Vellore Institute of Technology, Vellore.</h6>
              <h6>I have a major love for Hackathons, and I have attended about 14 of them.</h6>
              <h6>I have a passion for problem solving and dreaming.</h6>
              <br/>
              <h6>I build a lot of weird stuff on <a href="https://github.com/aritropaul">my github</a>, and sometimes I write about them <a href="/articles">here</a>.</h6>
              <h6>You can also find me posting content on <a href="https://aritro.work">my polywork profile</a>. It's a new fad. I love it.</h6>
            </div>
            <br/>
            <br/>
            <br/>
            <a href={resume}>Get my resume</a>
          </div>
          <div className="aritro four columns">
              <img src={aritro} width="200px"></img>
          </div>
        </div>
        <div className="extra row">
            <h1>Outside of Work</h1>
            <div className="title-secondary row">
              <Item title="Heat Waves" subtitle="GLASS ANIMALS" img="https://i.scdn.co/image/ab67616d00001e02ab9d1ae18b640b7b0ce390d4" type="LISTENING TO" link="https://open.spotify.com/track/6CDzDgIUqeDY5g8ujExx2f?si=29ac9b29ab114a4c"></Item>
              <Item title="Artemis" subtitle="ANDY WEIR" img="https://thebookloversboudoir.files.wordpress.com/2019/02/artemis.jpg" type="READING" link="https://www.andyweirauthor.com/books/artemis-tr/artemis-hc"></Item>
              <Item title="Grey's Anatomy" subtitle="ABC" img="https://cdn1.edgedatg.com/aws/v2/abc/GreysAnatomy/showimages/2afe459f0c13750ebf202999805e9029/634x848-Q90_2afe459f0c13750ebf202999805e9029.jpg" type="WATCHING" link="https://abc.com/shows/greys-anatomy"></Item>
              <Item title="Valorant" subtitle="RIOT GAMES" img="https://images.contentstack.io/v3/assets/bltb6530b271fddd0b1/blt881609ccc5207499/60b1901d3aac347374b5d9d1/VALORANT_YR1_ArticleHero_16_9.jpg" type="PLAYING" link="https://playvalorant.com"></Item>
            </div>
        </div>
        <Socials></Socials>
        <Footer></Footer>
      </div>
    )
}

export default IndexPage