import * as React from 'react'

const Item = ({title, subtitle, link, img, type}) => {
    return (
        <a className="item-link three columns" href={link}>
            <div className="item">
                <div className="class">
                    {type}
                </div>
                <img src={img} height="200px" width="200px"></img>
                <h3>{title}</h3>
                <h6>{subtitle}</h6>
            </div>
        </a>
    )
}

export default Item